import {Toast} from "vant";

export function loading() {
    return Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
    });
}

export function hideLoading(t) {
    t.clear();
}