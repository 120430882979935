<template>
  <AppInfo/>
</template>

<script>
import AppInfo from "@/components/AppInfo";

export default {
  name: 'App',
  components: {
    AppInfo
  },
  mounted() {

  }
}
</script>

<style>
html,body{
  margin: 0;
  height:100%;
  overflow: hidden;
}
#app{
  height: 100%;
}
body{
  background: var(--van-background);
  color:var(--van-text-color);
  font-family: var(--van-body-font)
}
h1, h2, h3, h4, h5, h6{
  margin-top: 5px;
  text-shadow: none;
  font-weight: 400;
  font-family: NotoSansHans-Regular,AvenirNext-Regular,proxima-nova,"Hiragino Sans GB","Microsoft YaHei","WenQuanYi Micro Hei","Open Sans","Helvetica Neue",Arial,sans-serif;
}
</style>
