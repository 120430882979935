import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios';
import VueAxios from 'vue-axios';
import {
    Toast
} from "vant";

import 'vant/lib/index.css';
import './style.css'

createApp(App).use(VueAxios, axios, Toast).mount('#app')
